<script setup>
import UserAuthForm from "@/Components/Auth/UserAuthForm.vue";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { Head } from "@inertiajs/vue3";
import GuestLayout from '@/Layouts/GuestLayout.vue';

const props = defineProps({
  canResetPassword: {
    type: Boolean,
    required: true,
  },
  status: {
    type: String,
  },
});
</script>

<template>
  <GuestLayout>
    <div class="flex flex-col sm:flex-row h-screen">
      <Head title="Login" />
      <div
        class="flex flex-col h-[100px] justify-center w-full sm:h-auto sm:w-1/2 bg-zinc-900 text-white sm:flex-1 sm:justify-between sm:p-10 p-4"
      >
        <a class="flex items-center text-lg font-medium ml-5 sm:ml-0" :href="route('login')">
          <ApplicationLogo id="logo" class="w-10 h-10 mr-2" />
          Synks
        </a>
        <blockquote class="hidden sm:block space-y-2">
          <p class="text-lg">
            Seamlessly connecting your business applications through a unified
            platform.
          </p>
        </blockquote>
      </div>
      <div class="w-full h-full md:h-auto sm:w-1/2 bg-white flex items-center justify-center">
        <div
          class="mx-auto flex flex-col justify-center space-y-6 w-4/5 max-w-[400px]"
        >
          <UserAuthForm :can-reset-password="props.canResetPassword" />
        </div>
      </div>
    </div>
  </GuestLayout>
</template>
