<script setup>
import { cn } from "@/lib/utils";
import { ref } from "vue";
import LoginForm from "@/Components/Auth/LoginForm.vue";
import ForgotPasswordForm from "@/Components/Auth/ForgotPasswordForm.vue";

const forgotPassword = ref(false);

const props = defineProps({
  canResetPassword: {
    type: Boolean,
    required: true,
  },
});
</script>

<template>
  <div :class="cn('grid gap-6')">
    <div class="flex flex-col space-y-2 text-center">
      <h1 class="text-2xl font-semibold tracking-tight">
        {{ forgotPassword ? "Forgot your password?" : "Login to your account" }}
      </h1>
      <p class="text-sm text-muted-foreground">
        {{ forgotPassword ? "Enter your email below to reset your password" : "Enter your email below to login to your account" }}
      </p>
    </div>
    <LoginForm v-if="!forgotPassword" @forgotPassword="forgotPassword = true" />
    <ForgotPasswordForm v-else @login="forgotPassword = false" />
  </div>
</template>
