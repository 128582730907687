<script setup>
import Button from "@/Components/ui/button/Button.vue";
import Input from "@/Components/ui/input/Input.vue";
import Label from "@/Components/ui/label/Label.vue";
import { Loader2 } from "lucide-vue-next";
import { cn } from "@/lib/utils";
import { ref, computed } from "vue";
import { useForm, Link } from "@inertiajs/vue3";

const isLoading = ref(false);
const submitted = ref(false);

const form = useForm({
  email: "",
  errors: null,
});

async function onSubmit(event) {
  event.preventDefault();
  isLoading.value = true;

  axios
    .post(route("password.request"), {
      email: form.email,
    })
    .then((response) => {
      if (response.status === 200) {
        submitted.value = true;
      }
    })
    .catch((error) => {
      form.errors = error.response.data.errors;
    })
    .finally(() => {
      isLoading.value = false;
    });
}

const firstError = computed(() => {
  if (form.errors) {
    return form.errors.email?.[0] || null;
  }
  return null;
});

const emit = defineEmits(["login"]);
</script>

<template>
  <div :class="cn('grid gap-6', $attrs.class ?? '')">
    <form @submit="onSubmit">
      <div class="grid gap-2">
        <div class="grid gap-1">
          <Label class="sr-only" for="email"> Email </Label>
          <Input
            id="email"
            placeholder="Email"
            type="email"
            auto-capitalize="none"
            auto-complete="email"
            auto-correct="off"
            :disabled="isLoading"
            v-model="form.email"
          />
        </div>
        <p v-if="firstError" class="text-sm text-red-600 dark:text-red-400">
          {{ firstError }}
        </p>
        <Button :disabled="isLoading || submitted">
          <Loader2 v-if="isLoading" class="w-4 h-4 mr-2 animate-spin" />
          {{ !submitted ? 'Email Password Reset Link' : 'Email Sent' }}
        </Button>
        <Button variant="link" @click="emit('login')"> Back to Login </Button>
      </div>
    </form>
  </div>
</template>
