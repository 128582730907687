<script setup>
import Button from "@/Components/ui/button/Button.vue";
import Input from "@/Components/ui/input/Input.vue";
import Label from "@/Components/ui/label/Label.vue";
import Checkbox from "@/Components/ui/checkbox/Checkbox.vue";
import { Loader2 } from "lucide-vue-next";
import { ref, computed, onMounted } from "vue";
import { useForm, router } from "@inertiajs/vue3";

const isLoading = ref(false);

onMounted(() => {
  const savedEmail = localStorage.getItem("userEmail");
  if (savedEmail) {
    form.email = savedEmail;
    form.remember = true;
  }
});

async function onSubmit(event) {
  event.preventDefault();
  isLoading.value = true;

  axios
    .post(route("login"), {
      email: form.email,
      password: form.password,
    })
    .then((response) => {
      if (form.remember) {
        localStorage.setItem("userEmail", form.email);
      } else {
        localStorage.removeItem("userEmail");
      }
      router.visit(route("dashboard"));
    })
    .catch((error) => {
      form.errors = error.response.data.errors;
    })
    .finally(() => {
      isLoading.value = false;
    });
}

const form = useForm({
  email: "",
  password: "",
  remember: false,
  errors: null,
});

const firstError = computed(() => {
  if (form.errors) {
    return form.errors.email?.[0] || form.errors.password?.[0] || null;
  }
  return null;
});

// Emit forgot password event
const emit = defineEmits(["forgotPassword"]);
</script>

<template>
  <form @submit="onSubmit">
    <div class="grid gap-2">
      <div class="grid gap-1">
        <Label class="sr-only" for="email"> Email </Label>
        <Input
          id="email"
          placeholder="Email"
          type="email"
          auto-capitalize="none"
          auto-complete="email"
          auto-correct="off"
          :disabled="isLoading"
          v-model="form.email"
        />
      </div>
      <div>
        <Label class="sr-only" for="password"> Password </Label>
        <Input
          id="password"
          placeholder="Password"
          type="password"
          auto-capitalize="none"
          auto-complete="password"
          auto-correct="off"
          :disabled="isLoading"
          v-model="form.password"
        />
      </div>
      <p v-if="firstError" class="text-sm text-red-600 dark:text-red-400">
        {{ firstError }}
      </p>
      <div class="flex items-center space-x-2 my-2">
        <Checkbox id="remember" :checked="form.remember" @update:checked="form.remember = $event" />
        <label
          for="remember"
          class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Remember email
        </label>
      </div>
      <Button :disabled="isLoading">
        <Loader2 v-if="isLoading" class="w-4 h-4 mr-2 animate-spin" />
        Login
      </Button>
      <Button variant="link" @click="emit('forgotPassword')">
        Forgot your password?
      </Button>
    </div>
  </form>
</template>
